import * as funciones from "@/_validations/funciones";

function f_letras(event) {
  funciones.SoloLetras(event);
}

function f_num(event) {
  funciones.SoloNumeros(event);
}

function hasError(field) {
  return field.$dirty ? !field.$error : null;
}

/*export const DateFormater = {
  toNormalDate(date, separator) {
    let newDay = new Date(date),
      day = ("0" + newDay.getDate()).slice(-2),
      month = ("0" + (newDay.getMonth() + 1)).slice(-2),
      year = newDay.getFullYear();

    separator = separator ? separator : "/";

    return `${day}${separator}${month}${separator}${year}`;
  },
}; */

export default {
  //MODELO
  methods: {
    hasError,
    f_letras,
    f_num,
  },
};
